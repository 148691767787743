var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('form-wizard', {
    staticClass: "wizard-vertical mb-3",
    attrs: {
      "color": "#7367F0",
      "title": null,
      "subtitle": null,
      "layout": "vertical",
      "finish-button-text": "Finalizar",
      "back-button-text": "Anterior",
      "next-button-text": "Próximo"
    },
    on: {
      "on-complete": _vm.formSubmitted
    }
  }, [_c('tab-content', {
    attrs: {
      "title": "Dados da Turma"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(" Dados da Turma ")]), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v(" Insira os Dados da Turma ")])]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nome da Turma",
      "label-for": "v-turma"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-turma",
      "placeholder": "Insira o nome da turma aqui"
    },
    model: {
      value: _vm.form.turma,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "turma", $$v);
      },
      expression: "form.turma"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "v-turno",
      "label": "Turno"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "turno",
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
      "selectable": function selectable(option) {
        return option.value != null;
      },
      "options": _vm.turnoOptions,
      "label": "text"
    },
    model: {
      value: _vm.form.turno,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "turno", $$v);
      },
      expression: "form.turno"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Ano",
      "label-for": "v-ano"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-ano",
      "placeholder": "Insira o ano da turma aqui"
    },
    model: {
      value: _vm.form.ano,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "ano", $$v);
      },
      expression: "form.ano"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }