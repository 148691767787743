<template>
  <div>
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      layout="vertical"
      finish-button-text="Finalizar"
      back-button-text="Anterior"
      next-button-text="Próximo"
      class="wizard-vertical mb-3"
      @on-complete="formSubmitted"
    >
      <!-- account datails tab -->
      <tab-content title="Dados da Turma">
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h5 class="mb-0">
              Dados da Turma
            </h5>
            <small class="text-muted"> Insira os Dados da Turma </small>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Nome da Turma"
              label-for="v-turma"
            >
              <b-form-input
                id="v-turma"
                v-model="form.turma"
                placeholder="Insira o nome da turma aqui"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label-for="v-turno"
              label="Turno"
            >
              <v-select
                id="turno"
                v-model="form.turno"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :selectable="option => option.value != null"
                :options="turnoOptions"
                label="text"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Ano"
              label-for="v-ano"
            >
              <b-form-input
                id="v-ano"
                v-model="form.ano"
                placeholder="Insira o ano da turma aqui"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import classroomService from '@/services/classroomService'
import { hideSpinner, showSpinner } from '@/services/spinService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
BCol, BFormGroup, BFormInput, BRow
} from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import vSelect from 'vue-select'

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  props: ['turmaId', 'id'],
  data() {
    return {
      turnoOptions: [
        { value: null, text: 'Nenhum Turno Selecionado' },
        { value: 'Manha', text: 'Manhã' },
        { value: 'Tarde', text: 'Tarde' },
        { value: 'Noite', text: 'Noite' },
      ],
      form: {
        id: null,
        turma: null,
        turno: null,
        ano: null,
        schoolId: this.$route.params.id,
      },
    }
  },
  mounted() {
    if (this.$route.params.turmaId) {
      this.getClassroomInfo()
    }
  },
  methods: {
    // eslint-disable-next-line no-undef
    async formSubmitted() {
      showSpinner()
      if (this.$route.params.turmaId) {
        this.fetchedClassrooms = await classroomService
          .update({ ...this.form, turno: this.form.turno.value })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Dados salvos com sucesso!',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$router.push('../turmas')
          })
          .catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title:
                      'Erro ao criar turma. Verifique os campos e tente novamente',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
          .finally(() => {
            hideSpinner()
          })
      } else {
        this.fetchedClassrooms = await classroomService
          .create({ ...this.form, turno: this.form.turno.value, schoolId: this.$route.params.id })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Dados salvos com sucesso!',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            // todo: ajeitar esse routing
            this.$router.push('../')
          })
          .catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title:
                      'Erro ao criar turma. Verifique os campos e tente novamente',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
          .finally(() => {
            hideSpinner()
          })
      }
    },
    async getClassroomInfo() {
      try {
        const classroom = await classroomService.getById(this.$route.params.turmaId)
        this.form = classroom
      } catch (error) {
        this.$bvToast.toast('Erro ao carregar Turma!', {
          title: 'Erro',
          variant: 'danger',
          solid: true,
          autoHideDelay: 5000,
        })
      }
    },

  },
}
</script>
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
